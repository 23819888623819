<template>
  
  <div>
       <div class="header">
             <ul class="left_menu">
                <div class="item"> 
              
                    <li class="active a">财务明细</li>
                  
                  
                </div>
                 <div  class="item">  
                         <li class="a" ><router-link to="/index2/xiaoxi" style="display:flex;    align-items: center">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i> <p style="width:15px;height:15px;background:red;text-align:center;font-size:12px;line-height:15px;border-radius:100%;color:#fff;margin-left:-10px;margin-top:-3px" v-if='result !==0' >{{result}}</p> 消息通知</router-link> </li>
                     <li style="cursor:pointer;" @click="dialogFormVisible = true">市场总监<i class="el-icon-caret-bottom"></i>  </li>
                </div>    

                       <el-dialog class="kk" title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                             <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3" placeholder="输入旧密码"  autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  <el-button slot="append"  @click="a33()">确认修改</el-button> </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                         <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                        </div>
                        </el-dialog>  
             </ul>
        </div>
         <div class="search"></div>
            <div class="content">
                  <ul class="content_ul">
                     <li style="background:#9351CC;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">收入总金额</p>
                          <p style="font-size:30px;margin: 0">{{infos.total/100}}</p>
                     </li>
                
                     <li style="background:#80B547;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                     <p style="font-size:14px;margin:12px 0">当日提成收入</p>
           <p style="font-size:30px;margin: 0">{{infos.totday_benefit/100}}</p>
                     </li>
                     <li style="background:#F5C900;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">本月收入</p>
                          <p style="font-size:30px;margin: 0">{{infos.month_benefit/100}}</p>
                     </li>
                  
                     <li style="background:#F5C900;color:#fff;height:90px;border-radius:10px;margin-right: 15px;width:50%;display:flex; align-items: center;   justify-content: space-between;">
                         <div>
 <p style="font-size:14px;margin:12px 0">可提现金额</p>
                          <p style="font-size:30px;margin: 0">{{info.balance/100}}</p>
                         </div>
                        <div style="display:flex;">
                            <p @click="mx()" style="font-size:14px;margin:12px 0;width:70px;margin-right:10px;border:1px solid #fff;padding:7.5px 10px;cursor: pointer;text-align:center">提现明细</p>
                          <p @click="dialogFormVisible1 = true" style="font-size:14px;margin:12px 0;width:70px;background: #fff;color:#0034FF;padding:7.5px 10px;text-align:center;margin-right:10px;cursor: pointer;">提现</p>
                       
         <el-dialog title="提现明细" :visible.sync="dialogFormVisible2">
                     <el-table
                    :border="true"
                    :data="details"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%">
                    
                  
                <el-table-column
                        prop="creattime" 
                    label="提现时间"
                        :show-overflow-tooltip="true" 
                      
                    >
                  
                    </el-table-column>
                   
                      <el-table-column
                     
                    label="金额"
                        :show-overflow-tooltip="true" 
                      
                    >
                   <template slot-scope="scope" >
                    <p > {{scope.row.fee/100}}</p>
                    </template>
                    </el-table-column>
                </el-table>

    
        </el-dialog>
  

                       <el-dialog class="kk" title="提现" :visible.sync="dialogFormVisible1">
  <el-form :model="form">
    <el-form-item label="提现金额" :label-width="formLabelWidth">
      <el-input v-model="form12.name" autocomplete="off"></el-input>
    </el-form-item>
   
  </el-form>
  <div slot="footer" class="dialog-footer">
    
    <el-button type="primary" @click="sure">确 定</el-button>
  </div>
</el-dialog>
                        </div>
                       
                     </li>
                     
                 </ul>
  <div class="search" style="padding:20px 40px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
            

              <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">订单编号</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input1">
                </el-input>
            </div>

         
      
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck1">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck2">查询</p>
            </div>
        </div>
        
                    
                    
                          <el-table
                    :border="true"
                    :data="datall"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%">
                    
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="id"
                    label="资金编号"
                    width="100">
                    </el-table-column>
                    <el-table-column
                        prop="orderid" 
                    label="原始订单编号"
                        :show-overflow-tooltip="true" 
                    width="100">
                    </el-table-column>

                    

                    
                
                      <el-table-column
                       
                    label="订单金额"
                        :show-overflow-tooltip="true" 
                        width="100"
                    >
                     <template slot-scope="scope" >
                    <p > {{scope.row.totalfee/100}}</p>
                    </template>
                    </el-table-column>
                         <el-table-column
                        prop="trade_time" 
                    label="交易时间"
                          width="200"
                      
                    ></el-table-column>
       
                      <el-table-column
                        prop="benefit" 
                    label="市场总监到账金额(元)"
                          width="100"
                      
                    >
                    <template slot-scope="scope" >
                    <p > {{scope.row.benefit/100}}</p>
                    </template>
                    </el-table-column>
           
                    
                      <el-table-column
                        prop="detail" 
                    label="分成状态"
                        :show-overflow-tooltip="true" 
                      
                    >
                  
                    </el-table-column>
                </el-table>
                     <el-pagination
      @size-change="handleSizeChange1"
      @current-change="handleCurrentChange1"
      :current-page="pagination1.page_index"
      :page-sizes="pagination1.page_sizes"
      :page-size="pagination1.page_size"
      :layout="pagination1.layout"
      :total='pagination1.total'
      style="margin-top:20px">
                 </el-pagination>
            </div>
  </div>
</template>

<script>
export default {
     methods:{
         mx(){
                   
             this.dialogFormVisible2 =true
          

	var myDate = new Date();
			
			 var getMonth= myDate.getMonth()+1;
			 
			var getMonth1= myDate.getMonth();
			
		 var getMonth2= myDate.getMonth()-1 ;
			 
		
			 if(getMonth<10){
				 var getMonth = '0'+getMonth
				  var getYear= myDate.getFullYear()
			
			 }
			 
			 if(getMonth1<10&&getMonth1>0){
				 	 var getMonth1 = '0'+getMonth1
					  var getYear1= myDate.getFullYear()
			 }else if(getMonth1==0){
				 	 var getYear1= myDate.getFullYear()-1
					 getMonth1 = 12
			 }
			 
			 if(getMonth2<10&&getMonth2>0 ){
			 	var getMonth2 = '0'+getMonth2
					 var getYear2= myDate.getFullYear()
			 }else if(getMonth2==0){
			 				 	 var getYear2= myDate.getFullYear()-1
								
			 					 getMonth2 = 12
			 }else if(getMonth2==-1){
				 var getYear2= myDate.getFullYear()-1
				 getMonth2 = 11
			 }
			
              this.$axios({
                        method: "get",
                        url: "/s/backend/bsite/api/user_tx_his",
                        params:{
                         "accessToken":  localStorage.getItem('token'),
							"t":getYear + '-'+getMonth
                        }
                     
                        }) .then(res=>{
                                    var a  =res.data.data
                                     this.$axios({
                        method: "get",
                        url: "/s/backend/bsite/api/user_tx_his",
                        params:{
                     	"accessToken":  localStorage.getItem('token'),
							"t":getYear2 + '-'+getMonth2
                        }
                     
                        }) .then(res=>{
                      var b = a.concat(res.data.data)
  this.$axios({
                        method: "get",
                        url: "/s/backend/bsite/api/user_tx_his",
                        params:{
                     	"accessToken":  localStorage.getItem('token'),
							"t":getYear1 + '-'+getMonth1
                        }
                     
                        }) .then(res=>{
                              this.details = b.concat(res.data.data)
                        })

                        })

                        })
            
                     

                                   



         },
         sure(){
             this.$axios({
                        method: "post",
                        url: "/s/backend/api/web_tx",
                        data:{
                            "fee": this.form12.name*100,
                           "jobnumber": this.info.jobnumber
                        }
                     
                        }) .then(res=>{
                                 if(res.data.code == 0 ){
                                        location.reload()
                                       this.$message({
                                message:'成功',
                                type: 'success'
                                    });
                                    this.dialogFormVisible1 = false
                                 }else if(res.data.code == -1){
                                         this.$message({
                                message:res.data.msg,
                                type: 'error'
                                    });
                                      this.dialogFormVisible1 = false
                                 }
                        })
         },
           handleSizeChange1(val) {
this.pagination1.page_size=val

 this.$axios({
                        method: "post",
                        url: "/s/backend/api/cw_detail_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                        page :this.pagination1.page_index,
       limit :val,
                           'type':3
                        },
                     
                        }) .then(res=>{
                                this.datall= res.data.data
                        })
    
    



            
                 
      },
      handleCurrentChange1(val) {
       this.pagination1.page_index=val
     

 this.$axios({
                        method: "post",
                        url: "/s/backend/api/cw_detail_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
               page :val,
      limit :this.pagination1.page_size,
                           'type':3
                        },
                     
                        }) .then(res=>{
                                this.datall= res.data.data
                        })
    
  
        
      },

         ck1(){
               this.input1=''
               this.input2=''
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/cw_detail_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                         'page':1,
                          'limit':10,
                         'type':3
                        },
                     
                        }) .then(res=>{
                                this.datall= res.data.data
                        })
         },
         ck2(){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/cw_detail_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                         'page':1,
                          'limit':10,
                          'orderid':this.input1,
                          'name':this.input2,
                              'type':3

                        },
                     
                        }) .then(res=>{
                                this.datall= res.data.data
                        })
    
         },
           a11(){
               

                  this.$confirm('此操作将修改昵称, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
            this.$axios({
                        method: "post",
                        url: "/s/backend/api/userupdate",
                        params: {
                        accessToken:localStorage.getItem('token'),
                   
                        },
                        data:{
                            "jobnumber": localStorage.getItem('jobnumber'),
                            "name": this.info.name
                        }
                        }) .then(res=>{
                        
                           
                          var cardNumObj = { arealist:res.data.data }
                           var cardNumObj1 = { yyzcurl:'' }
                          var assignObj =  Object.assign(this.info,cardNumObj);
                          var assignObj1 = Object.assign(assignObj,cardNumObj1)
                                        this.$axios({
                                        method: "post",
                                        url: "/s/backend/api/userupdate",
                                        params: {
                                        accessToken : localStorage.getItem('token')
                                        },
                                        data:assignObj1,
                                        }) .then(res=>{
                                            this.dialogFormVisible = false
                                            this.$message({
                                message:'修改成功',
                                type: 'success'
                                    });
                        })
        .catch(function(){
           
        })  
                  
                        })
        .catch(function(){
           
        })  


        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        });
      



 

      
       
        },
        a22(){
            this.password2=true
         
              
        },
        a33(){

              this.$confirm('此操作将修改密码, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                                message:'修改成功',
                                type: 'success'
                                    });
                            }else{
                                    this.dialogFormVisible = false
                                    this.$message({
                                        message:res.data.msg,
                                        type: 'success'
                                            });
                            }
                            })
            .catch(function(){
            
            })  
            }

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        });







            
        },
          tuichu(){
            this.dialogFormVisible = false;
            window.localStorage.removeItem('token')
                     this.$router.push({ name: 'login'})
        },
        a33(){
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                    message:'修改成功',
                    type: 'success'
                        });
                            }else{
                                        this.$message({
                    message:res.data.msg,
                    type: 'success'
                        });
                            }
                            })
            .catch(function(){
            
            })  
            }
        },
    },
 data(){
        return{
            k2:[],  k3:[],
            k1:[],
            details:[],
            dialogFormVisible2:false,
            dialogFormVisible1:false,
             form12: {
          name: '',
             },
                pagination1:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
            input1:'',
            input2:'',
            datall:[],
                dialogFormVisible:false, form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px',
        info:[],
infos:[],
result:'',
            activeName: 'first',
    
                 tableData: [],
                    data1:[],
                    data2:[],
                 password:'',
                   password2:false,
                  password3:''
        }
        },
       
        mounted(){
                            
this.$axios.get("/s/backend/message/get_message_unread", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})
  .then(res=>{
         this.result = res.data.result
          
        })
        .catch(function(){
           
        })   
  this.$axios({
                        method: "post",
                        url: "/s/backend/api/cw_grbenefit_statistic",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                     
                        }) .then(res=>{
                          this.infos = res.data.result
       
                        })
    
    this.$axios({
                        method: "post",
                        url: "/s/backend/api/cw_ptcje",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                     
                        }) .then(res=>{
                             
            let arrs = []
            let arrs1 = []
 
            res.data.result.forEach(e => {
            arrs.push(e.month)
             arrs1.push(e.total)
     
         })
   
     
                  let myChartDrawer =  this.$echarts.init(document.getElementById('myChart1'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: '平台成交额',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD','#0034FF'],
                    legend: {
                        data: ['成交交易'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '成交易额',
                            type: 'bar',
                            
                            data:arrs1
                        },
                       
                      
                    ]
                };

                myChartDrawer.setOption(option);
                            
                        })







  this.$axios({
                        method: "post",
                        url: "/s/backend/api/cw_ptbenefit",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                     
                        }) .then(res=>{
                             
            let arrs = []
            let arrs1 = []
 
            res.data.result.forEach(e => {
            arrs.push(e.month)
             arrs1.push(e.total)
     
         })
     
     
                  let myChartDrawer =  this.$echarts.init(document.getElementById('myChart2'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: '平台收益统计',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD'],
                    legend: {
                        data: ['成交交易'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '成交易额',
                            type: 'bar',
                            
                            data:arrs1
                        },
                       
                      
                    ]
                };

                myChartDrawer.setOption(option);
                            
                        })








   this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :10  }
        })

     .then(res=>{
    
         var data1 = res.data.data
   this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :9  }
        })

     .then(res=>{
     
     this.tableData=data1.concat(this.data2)

        })
        .catch(function(){
           
        })
        })
        .catch(function(){
           
        })

  this.$axios({
                        method: "post",
                        url: "/s/backend/api/cw_detail_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                         'page':1,
                          'limit':10,
                           'type':3
                        },
                     
                        }) .then(res=>{
                                this.datall= res.data.data
                                this.pagination1.total =res.data.count
                        })
    


    
         
  

               this.$axios.get("/s/backend/api/user_detail", {
        params: { 'accessToken':localStorage.getItem('token'),
        'jobnumber':localStorage.getItem('jobnumber')
        }
        })

     .then(res=>{
          this.info = res.data.data
           
        })
        .catch(function(){
           
        })
        }
}
</script>

<style scoped>

/deep/.el-dialog__wrapper{
    top:-50px
}

/deep/.kk .el-dialog{
        width: 25%;

    float: right;
  
}
    .content{
        background: #f5f5f5;
        padding:10px 40px;
    }
 .content .content_ul{
            display: flex;
            justify-content: space-between;margin:30px 0
        }
         .content .content_ul li{
             width: 32%;padding-left: 15px;
         }




.item li:hover{
    color: #0034FF;
}
.item li a:hover{
    color: #0034FF !important;
}
   /deep/.el-table th > .cell {
      text-align: center;

    }

    /deep/.el-table .cell {
      text-align: center;

    }

/deep/.el-tabs--card>.el-tabs__header{
    border-bottom: none !important;
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
    .header{
            min-height: 57px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
            cursor: pointer;
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-120%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
</style>